.landing {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fetching {
    margin-top: 50px;
}

.temperature{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap:20px;
}
.temperature .card{
    padding: 10px;
}
