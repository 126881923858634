.footer {
    position: fixed;
    display: flex;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke;
    color: white;
    text-align: center;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.footer a{
    color: white;
    text-decoration: none;
}
.logo {
    justify-self: end;
    height: 30px;
}