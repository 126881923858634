.books{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.books ul{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.fetching{
    margin-top: 100px;
}
.books .card{
    margin: 10px;
    width: 300px;
    text-decoration: none;
    color: inherit;
}
.books a{
    text-decoration: none;
    color: inherit;
}