#experienceDiv {
  display: grid;
  position: relative;
  width: 45vw;
  height: 100%;
  color: black;
  font-size: 12px;
  padding: 0px 20px 10px 10px;
  max-width: 400px;
  @media screen and (max-width: 768px) {
    width: 350px;
    font-size: 10px;
  }
  @media screen and (max-width: 576px) {
    width: 300px;
    font-size: 9px;
    margin-top: 20px;
  }

}
#jobs {
  display: grid;
  position: relative;
  font-size: 0.5em;
  list-style: none;
  padding: 10px;
  grid-gap: 5px;
  gap: 5px;
  border-radius: 20px;
}
.job {
  position: relative;
  left: auto;
  right: auto;
  list-style: none;
  border-radius: 20px;
  background-color: white;
  border-style: outset;
  border-width: 5px;
  padding-bottom: 5px;
  min-height: 150px;
  text-align: left;
  font-size: 5px;
}
.job h1{
  font-size: small;
  @media screen and (max-width: 576px) {
    font-size: x-small;
  }
}
#titleDiv {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: dodgerblue;
  width: 45%;
  height: 50px;
  text-align: center;
  padding: 10px;
}
#title {
  color: white;
}
#employer {
  position: absolute;
  top: 5px;
  left: 10px;
  color: dodgerblue;
}
#location {
  position: absolute;
  top: 30px;
  left: 10px;
  font-size: 8px;
  @media screen and (max-width: 576px) {
    font-size: 6px;
  }
  @media screen and (max-width: 768px) {
    font-size: 7px;
  }
}
#date {
  position: absolute;
  top: 31px;
  left: 80px;
  font-size: 8px;
  @media screen and (max-width: 768px) {
    top: 29px;
    left: 70px;
    font-size: 7px;
  }

  @media screen and (max-width: 576px) {
    top: 30px;
    left: 57px;
    font-size: 6px;
  }
}
#details {
  position: absolute;
  top: 55px;
  left: -10;
  font-size: 140%;
  margin-right: 3px;
  padding-left: 25px;
  @media screen and (max-width: 576px) {
    font-size: 120%;
  }
  
}
#details li{
    margin-bottom: 0.5%;
  }