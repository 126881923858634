* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.header {
    background-color: #f1f1f1;
    padding: 20px 10px;
    height: 80px;
    margin-bottom: 10px;
}

.header a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
}

.header a.logo {
    font-size: 25px;
    font-weight: bold;
    color: var(--main-color);
}

.header a:hover {
    background-color: #ddd;
    color: black;
}

.header a.active {
    background-color: dodgerblue;
    color: white;
}

.header-right {
    float: right;
    display: flex;
}

@media screen and (max-width: 700px) {
    .header a {
        float: none;
        display: block;
        text-align: left;
        font-size: 13px;
    }
    .header a.logo {
        margin-bottom: 10px;
    }

    .header-right {
        float: none;
    }
    .header{
        height: 120px;
    }
}
.dropdown{
    position: relative;
    top: 7px;
}