#contactintro{
  color: var(--main-color);
  margin: 50px;
}
#contactme{
  background-color: white;
  border-color: var(--trinary-color);
  color: white;
  padding: 10px;
}
#contact {
  align-items: center;
  align-self: center;
  margin-bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  h2 {
    text-align: center;
    color: var(--main-color);
  }
  h1{
    color: black;
  }
  
  form{
    
    align-items: center;
    align-self: center;
    // position: relative;
    max-width: 700px;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: column;
    // gap: 10px;
    background-color: var(--main-color);
    width: 100%;
    border-radius: 20px;
    border-style: outset;
    border-width: 5px;
    border-color: var(--main-color);
   
    
    p,h2{
      // margin-top: 20px;
      align-self: start;
      position: relative;
      color: white;
      left: 30px;
      @media screen and (max-width: 800px) {
        left:20px;
        grid-template-columns: 1fr;
        font-size: 1em;
      }
      @media screen and (max-width: 600px) {
        left:10px;
        grid-template-columns: 1fr;
        font-size: 0.75em;
      }
    }
    #name {
      width: 90%;
      height:10%;
      border-radius: 5px;
      background-color: whitesmoke;
      border-color: none;
      border-style: none;
      align-self: center;
    }
    #email {
      width: 90%;
      height:10%;
      border-radius: 5px;
      background-color: whitesmoke;
      border-color: none;
      border-style: none;
      align-self: center;
    }
    #subject {
      width: 90%;
      height:10%;
      border-radius: 5px;
      background-color: whitesmoke;
      border-color: none;
      border-style: none;
      align-self: center;
    }
    #message {
      width: 90%;
      height: 40%;
      border-radius: 5px;
      background-color: whitesmoke;
      border-color: none;
      border-style: none;
      align-self: center;
      margin-bottom: 10px;
    }
    
    .button {
      color: var(--main-color);
      background-color: white;
      width: 100px;
      height: 30px;
      border-radius: 20px;
      border-color: var(--main-color);
      margin-bottom: 20px;
      transition: 250ms ease-in-out;
      gap: 10px;
      align-self: center;
      
    }
    #sendButton:hover {
      transform: translateY(20%);
    }
  
  }
}
