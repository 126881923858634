.section{
    padding: 36px 72px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
@media screen and (min-width: 1300px) {
    #projects.section{
      padding: 0 300px;  
    }
}
#projects{
    h1{
        color: var(--main-color)
    }
    padding-top: 10px;

}
.projects{
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    align-content: space-between;
    .project{
            border: 5px solid white;
            border-style: outset;
            width: 27%;
            flex: none;
            margin: 6px;
            height: 160px;
            border-radius: 2vw;
            background-color: white;
            min-width: 240px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 300px;
            transition-delay: 500ms;
        img{
            width: 90%;
            height: 50%;
            background-color: transparent;
        }
    }
    #csclub.unhovered{
        background-color: hsl(0, 0%, 90%);
        border: 5px outset white;
        background-image: url("../Media/pepperdine-university.jpg");
        background-position: center;
        background-size: cover;
        background-blend-mode: soft-light;
        background-repeat: no-repeat;
        img {
            height: 30%;
            width: 80%;
        }
    }
    #Pepperplan.unhovered{
        background-color: black;
        border: 5px outset black;
        background-image: url("../Media/Pepperplan-photo.png");
        background-position: center;
        background-size: cover;
        background-blend-mode: luminosity;
        background-repeat: no-repeat;
        img{
            height: 35%;
        }
    }
    .appear{
        display:block;
    }
    .hovered{
        background-color: var(--secondary-color);
        border-color: var(--secondary-color) ;
        transition-delay: 300ms;   
        transform-style: preserve-3d; 
    }
    #ipser.unhovered{
        background-color: white;
        background-position: center;
        border-color: white;
        background-size: 100%;
        background-blend-mode: soft-light;
        background-repeat: no-repeat;
    img{
        width: 80%;
        height: 40%;
    }
}
    // #ipser:hover{
    //     background: var(--main-color);
    //     border-color: var(--main-color);
    //     transition-delay: 200ms;
    //     img {
    //         display: none;
    //     }
    // }
}



#internship.unhovered{
    background-color: #2d3e50;
    background-position: center;
    border-color: #2d3e50;
    background-image: url("../Media/Office-photo.jpg");
    background-position: center;
    background-size: cover;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    img{
        height: 50%;
    }
}
#portfolio.unhovered{
    background-color: white;
    background-position: center;
    border-color: black;
    background-image: url("../Media/react_animation.jpg");
    background-position: center;
    background-size: 100%;
    background-blend-mode: luminosity;
    background-repeat: no-repeat;
    img {
        height: 85%;
    }
}

#kotlin.unhovered{
    background: #1e9eff;
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    border-color: #1e9eff;
    img {
        width: 90%;
        height: 90%;
    }
}

.exploreBtn{
        margin: 20px;
        background-color: var(--main-color);
        text-decoration: none;
        font-size: 0.7em;
        border-radius: 20px;
        border-color: white;
        margin-bottom: 20px;
        transition: 250ms ease-in-out;
        border-style: outset;
        align-self: center;
        align-items: center;
        display: flex;
        justify-content: center;  
}
.hoverDiv{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    font-size: x-large;
    h2{
        margin:0;
    }
    .exploreBtn{
        margin: 0;
        height: 1.5rem;
        width: fit-content;
        padding: 8px;
        color: var(--main-color);
        background-color: white;
        border-color: white;
    }
    p{
        margin-bottom: 0.5rem;
    }
}
.exploreBtn:hover{
        transform: translateY(10%);
    }
.hoverTxt{
    font-size: 0.5em;
}
.disappear{
        display:none;
    }
.hoverDiv.disappear{
    display: none;
}