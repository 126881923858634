.login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login a{
    margin: 10px;
}
.login .logo{
    justify-self: end;
    height: 50px;
}
.login-form{
   flex-direction: column;
    gap: 10px;
    margin-top: 20px; 
}
